import React, { Component, Fragment } from 'react';
import Slider from 'react-slick';
import { slideSlick } from '../config/script';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Header from '../component/header/Header';
import Footer from '../component/footer/Footer';

import ServiceTwo from '../component/service/ServiceTwo';
import CounterOne from '../component/counters/CounterOne';
import Testimonial from '../component/testimonial/Testimonial';
import AboutTwo from '../component/HomeLayout/AboutTwo';
import Portfolio from '../component/HomeLayout/Portfolio';
import BlogContent from '../component/blog/BlogContent';
import BrandTwo from '../component/brand/BrandTwo';
import Helmet from '../component/common/Helmet';

const SlideList = [
  {
    textPosition: 'text-center',
    bgImage: 'bg_image--12',
    category: '',
    title: 'Apputveckling',
    description:
      'Vi har hjälpt några av Sveriges största företag att skapa appar. Hur kan vi hjälpa er?',
    buttonText: 'Kontakta oss',
    buttonLink: '/kontakt',
  },
  {
    textPosition: 'text-center',
    bgImage: 'bg_image--15',
    category: '',
    title: 'Säkerhet',
    description:
      'Är era system tillräckligt säkra? Vi testar och åtgärdar eventuella brister.',
    buttonText: 'Kontakta oss',
    buttonLink: '/kontakt',
  },
  {
    textPosition: 'text-center',
    bgImage: 'bg_image--13',
    category: '',
    title: 'Drift',
    description:
      'Vi samarbetar med Amazon för att kunna erbjuda drift utan störningar så att ni får nöjdare kunder.',
    buttonText: 'Kontakta oss',
    buttonLink: '/kontakt',
  },
];

class Startup extends Component {
  render() {
    const PostList = BlogContent.slice(0, 3);
    return (
      <Fragment>
        <Helmet pageTitle="Apputveckling i Stockholm" />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logo="light"
        />

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation slider-startup">
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
              {SlideList.map((value, index) => (
                // Start Single Slider
                <div
                  className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                  key={index}
                  data-black-overlay="8"
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ''}
                          {value.title ? (
                            <h1 className="title theme-gradient">
                              {value.title}
                            </h1>
                          ) : (
                            ''
                          )}
                          {value.description ? (
                            <p className="description">{value.description}</p>
                          ) : (
                            ''
                          )}
                          {value.buttonText ? (
                            <div className="slide-btn">
                              <a
                                className="rn-button-style--2 btn-primary-color"
                                href={`${value.buttonLink}`}
                              >
                                {value.buttonText}
                              </a>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                // End Single Slider
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start About Area */}
        <div className="about-area ptb--120">
          <AboutTwo />
        </div>
        {/* End About Area */}
        <div className="service-area ptb--75  bg_image bg_image--3">
          <div className="container">
            <ServiceTwo />
          </div>
        </div>

        {/* Start Portfolio Area */}
        <div className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner mb--55">
            {/* <Portfolio /> */}
          </div>
        </div>
        {/* End Portfolio Area */}

        {/* Start CounterUp Area */}
        <div className="rn-counterup-area pt--25 pb--110 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="fontWeight500 mb--10">Fun Facts</h3>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
        </div>
        {/* End CounterUp Area */}

        {/* Start Testimonial Area */}
        <div className="rn-testimonial-area bg_color--5 ptb--120">
          <div className="container">
            <Testimonial />
          </div>
        </div>
        {/* End Testimonial Area */}

        {/* Start Brand Area */}
        <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </Fragment>
    );
  }
}
export default Startup;
