import React, { Component } from 'react';
import { FiCast, FiLayers, FiUsers, FiMonitor } from 'react-icons/fi';
import { Link } from 'gatsby';

const ServiceList = [
  {
    icon: <FiMonitor />,
    title: 'Apputveckling',
    description:
      'Vi hjälper till att bygga några av de mest omtyckta apparna i Sverige. Både till stora och små företag.',
    path: '/apputveckling',
  },
  {
    icon: <FiLayers />,
    title: 'Säkerhet',
    description:
      'Vi ser till så att systemen verkligen är säkra och stabila. Både när det kommer till stora och små system.',
    path: '/sakerhet',
  },
  {
    icon: <FiCast />,
    title: 'Appstore-optimering',
    description:
      'Precis som det är viktigt att synas högst upp i Google så är det nu lika viktigt att din app syns.',
    path: '/appstore-optimering',
  },
  {
    icon: <FiUsers />,
    title: 'Drift och underhåll',
    description:
      'Vi ser till så din app hela tiden fungerar som den ska oavsett belastning.',
    path: '/drift',
  },
];

class ServiceTwo extends Component {
  render() {
    let title = 'Tjänster',
      description =
        'Vi designar, bygger, lanserar och driftar prisbelönta appar och tjänster till miljontals användare runt om i världen.';
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
              <h2 className="title">{title}</h2>
              <p>{description}</p>
              <div className="service-btn">
                <Link className="btn-transparent rn-btn-dark" to="/kontakt">
                  <span className="text">Vill ni också ha hjälp?</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-12 mt_md--50">
            <div className="row service-one-wrapper">
              {ServiceList.map((val, i) => (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                  <Link to={val.path}>
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceTwo;
