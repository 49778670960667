import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12">
            <Tabs>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Vi trodde aldrig vi skulle kunna lansera en produkt så
                      snabbt och med så fint resultat.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Jonas Hammarberg </span> - CEO, Awesome Group AB
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>Working with the team is always a pleasure.</p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Garrett Rice </span> - Senior partnership manager,
                      Apple Inc.
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Ni har gjort ett grymt jobb och vart mycket viktig för den
                      fina mobilapp vi har. Det har vart en ära att få jobba
                      tillsammans med er och jag hoppas vi får möjlighet att
                      göra det igen i framtiden!
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Niclas Molinder </span> - VD, Session INC.
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Vi har jobbat med App Universe i 2 år nu och har extremt
                      nöjda kunder och ett riktigt bra resulltat.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Carl Tottie </span> - Chef, Acando Sverige AB.
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Det är så enkelt att jobba med App Universe, får saker
                      gjorda mycket snabbare än innan.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Hemant Bamma </span> - Developer lead, CGI AB
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Vi vill definitivt fortsätta det fina samarbete vi har så
                      länge som möjligt.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Åza Zastell </span> - Enhetschef, Swedbank AB.
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabList className="testimonial-thumb-wrapper">
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <picture>
                        <source
                          srcSet="/assets/images/client/testimonial-2.webp"
                          type="image/webp"
                        />
                        <img
                          src="/assets/images/client/testimonial-2.jpg"
                          alt="Testimonial Images"
                        />
                      </picture>
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <picture>
                        <source
                          srcSet="/assets/images/client/testimonial-3.webp"
                          type="image/webp"
                        />
                        <img
                          src="/assets/images/client/testimonial-3.jpg"
                          alt="Testimonial Images"
                        />
                      </picture>
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <picture>
                        <source
                          srcSet="/assets/images/client/testimonial-4.webp"
                          type="image/webp"
                        />
                        <img
                          src="/assets/images/client/testimonial-4.jpg"
                          alt="Testimonial Images"
                        />
                      </picture>
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <picture>
                        <source
                          srcSet="/assets/images/client/testimonial-6.webp"
                          type="image/webp"
                        />
                        <img
                          src="/assets/images/client/testimonial-6.jpg"
                          alt="Testimonial Images"
                        />
                      </picture>
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <picture>
                        <source
                          srcSet="/assets/images/client/testimonial-7.webp"
                          type="image/webp"
                        />
                        <img
                          src="/assets/images/client/testimonial-7.jpg"
                          alt="Testimonial Images"
                        />
                      </picture>
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <picture>
                        <source
                          srcSet="/assets/images/client/testimonial-8.webp"
                          type="image/webp"
                        />
                        <img
                          src="/assets/images/client/testimonial-8.jpg"
                          alt="Testimonial Images"
                        />
                      </picture>
                    </div>
                  </div>
                </Tab>
              </TabList>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Testimonial;
