import React, { Component } from 'react';

class AboutTwo extends Component {
  render() {
    let title = 'Om oss',
      description =
        'På App Universe designar vi, bygger, lanserar och driftar appar och digitala tjänster till miljontals personer runt om i världen​.';
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <picture>
                    <source
                      className="w-100"
                      srcSet="/assets/images/about/officework.webp"
                      type="image/webp"
                    />
                    <img
                      className="w-100"
                      src="/assets/images/about/officework.jpg"
                      alt="About Images"
                    />
                  </picture>
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{title}</h2>
                    <p className="description">{description}</p>
                  </div>
                  <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Vilka vi är</h3>
                        <p>
                          Några av de bästa apputvecklarna och UX-designare i
                          Sverige som har skapat prisbelönta appar.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Vad vi gör</h3>
                        <p>
                          Vi skapar och driftar appar till iOS och Android samt
                          ser till så systemen är säkra.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AboutTwo;
